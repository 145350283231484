/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from "react";
import AsyncSelect from "react-select/async";
import { useQuery, useMutation } from "react-query";
import { api } from "../../../api";
import toast from "react-hot-toast";
import { useCustomToast } from "../../../components/customtoast/CustomToastProvider";
import CustomDropdown from "../../../components/CustomDrpdown";
import { ScaleLoader } from "react-spinners";
import { profile } from "../../../api/profile";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { formatDate, reverseDate } from '../../../utils/dateFormatter';

const useDebounce = (fn, delay) => {
  let timeout;
  // return function that takes arg and apply to the function
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      fn.apply(this, args);
    }, delay);
  };
};

function EditEducationModal({
  closeModal,
  data,
  refetchEducation,
  refetchStatus,
}) {
  const user = JSON.parse(sessionStorage.getItem('user'))?.user
  const customToast = useCustomToast();
  const [institutionSearch, setInstitutionSearch] = useState("");
  const [institutions, setInstitutions] = useState([]);
  const [courses, setCourses] = useState([]);
  const [searchInstitutionLoading, setSearchInstitutionLoading] = useState(false)
  const [searchCourseLoading, setSearchCourseLoading] = useState(false)

  const { isLoading: institutionLoading, refetch } = useQuery(
    "get-institution",
    () => {
      return profile.getInstitutionsv2(institutionSearch);
    },
    {
      onSuccess: (response) => {
        setInstitutions(
          response?.data?.map((ins) => {
            return { label: ins?.name, value: ins?.id };
          })
        );
      },
      refetchOnWindowFocus: false,
    }
  );

  const getInstitutionsMutation = useMutation(profile.getInstitutionsv2, {
    onSuccess: (response) => {
      setInstitutions(
        response?.data?.map((ins) => {
          return { label: ins?.name, value: ins?.id };
        })
      );
      setTimeout( () => setSearchInstitutionLoading(false) , 100 )
    },
  });

  const getCoursesMutation = useMutation(profile.getCoursesv2, {
    onSuccess: (response) => {
      setCourses(
        response?.data?.map((ins) => {
          return { label: ins?.title, value: ins?.id };
        })
      );
      setTimeout( () => setSearchCourseLoading(false) , 100 )
    },
  });

  const { isLoading } = useQuery("get-courses", profile.getCoursesv2, {
    retry: false,
    refetchOnReconnect: false,

    onSuccess: (data) => {
      setCourses(
        data?.data?.map((ins) => {
          return { label: ins?.title, value: ins?.id };
        })
      );
    },
    refetchOnWindowFocus: false,
  });
  const { data: degrees, isLoading: degreesLoading } = useQuery(
    "get-degree",
    profile.getDegreesv2,
    { retry: false, refetchOnReconnect: false, refetchOnWindowFocus: false }
  );

  const { data: classOfDegrees, isLoading: classOfDegreeLoading } = useQuery(
    "get-classOfDegrees",
    profile.getClassOfDegreesv2,
    { retry: false, refetchOnReconnect: false, refetchOnWindowFocus: false }
  );

  const [startDate, setStartDate] = useState(reverseDate(data?.start_date));
  const [endDate, setEndDate] = useState(reverseDate(data?.end_date));
  const { isLoading: editEducationLoading, mutateAsync} = useMutation(profile.updateUserEducationv2)
  // handle seletced states
  const [selectInstitution, setSelectedInstitution] = useState(
    data?.institution?.name
  );
  const [selectCourse, setSelectedCourse] = useState(data?.course?.title);
  const [selectDegree, setSelectedDegree] = useState(data?.degree?.name);
  // const [selectState, setSelectedState] = useState(data?.location);
  const [selectClassOfDegree, setSelectedClassOfDegree] = useState(
    data?.class_of_degree?.name
  );
  const [degreeId, setDegreeId] = useState(data?.degree?.id);
  const [courseId, setCourseId] = useState(data?.course?.id);
  const [selectInstitutionId, setSelectedInstitutionId] = useState(
    data?.institution?.id
  );
  const [classOfDegreeId, setClassOfDegreeId] = useState(
    data?.class_of_degree?.id
  );

  const [institutionValid, setInstitutionValid] = useState(true);
  const [courseValid, setCourseValid] = useState(true);
  const [degreeValid, setDegreeValid] = useState(true);
  const [classofDegreeValid, setClassOfDegreeValid] = useState(true);
  const [startDateValid, setStartDateValid] = useState(true);
  const [endDateValid, setEndDateValid] = useState(true);

  const filteredInstitutions = (inputValue) => {
    return institutions?.data
      .filter((institution) =>
        institution.name.toLowerCase().includes(inputValue.toLowerCase())
      )
      .map((i) => ({
        value: i.id,
        label: i.name,
      }));
  };

  const loadOptions = (inputValue, callback) => {
    setTimeout(() => {
      callback(filteredInstitutions(inputValue));
    }, 200);
  };

  const filteredDegree = (inputDegree) => {
    return degrees?.data
      .filter((degree) =>
        degree.name.toLowerCase().includes(inputDegree.toLowerCase())
      )
      .map((deg) => ({
        value: deg.id,
        label: deg.name,
      }));
  };
  const degreeOptions = (inputValue, callback) => {
    setTimeout(() => {
      callback(filteredDegree(inputValue));
    }, 200);
  };

  const filteredCourses = (inputCourse) => {
    return courses?.data
      .filter((course) =>
        course.title.toLowerCase().includes(inputCourse.toLowerCase())
      )
      .map((c) => ({
        value: c.id,
        label: c.title,
      }));
  };
  const courseOptions = (inputCourse, callback) => {
    setTimeout(() => {
      callback(filteredCourses(inputCourse));
    }, 200);
  };

  const filteredclassOfDegrees = (inputClassOfDegree) => {
    return classOfDegrees?.data
      .filter((classOfDegree) =>
        classOfDegree.name
          .toLowerCase()
          .includes(inputClassOfDegree.toLowerCase())
      )
      .map((cg) => ({
        value: cg.id,
        label: cg.name,
      }));
  };

  const classOptions = (inputClassOfDegree, callback) => {
    setTimeout(() => {
      callback(filteredclassOfDegrees(inputClassOfDegree));
    }, 200);
  };

  const updateEducation = async (e) => {
    e.preventDefault();

    setInstitutionValid(selectInstitution !== "");
    setCourseValid(selectCourse !== "");
    setDegreeValid(selectDegree !== "");
    setClassOfDegreeValid(selectClassOfDegree !== "");
    setStartDateValid(startDate !== "");
    setEndDateValid(endDate !== "");

    if (
      !institutionValid ||
      !courseValid ||
      !degreeValid ||
      !classofDegreeValid ||
      !startDate ||
      !endDate
    ) {
      toast.error("All fields are required");
      return;
    } else if (new Date(startDate).getTime() > new Date(endDate).getTime()) {
      toast.error("Start date cannot be more than end date.");
      return;
    }

    let formData = {
      name: selectInstitution,
      degree: selectDegree,
      degree_id: degreeId,
      course: selectCourse,
      course_id: courseId,
      class_of_degree: selectClassOfDegree,
      class_of_degree_id: classOfDegreeId,
      start_date: formatDate(startDate),
      end_date: formatDate(endDate),
      institution: selectInstitution,
      institution_id: selectInstitutionId,
    };

    try {
      toast.loading("loading...");
      const response = await mutateAsync({
        id: data?.id, userId: user?.user_id, payload: formData
      });
      toast.remove();
      customToast.success(response.message);
      await refetchEducation();
      await refetchStatus();
      closeModal(false);
    } catch ({ response }) {
      toast.remove();
      customToast.error(response?.data?.message);
    }
  };

  const deleteEducation = async (e) => {
    if (confirm("Are you sure you want to delete this")) {
      try {
        const res = await profile.deleteUserEducationv2({ id: data?.id, userId: user?.user_id });
        customToast.success(res?.message);
        await refetchEducation();
        await refetchStatus();
        closeModal(false);
      } catch ({ response }) {
        toast.remove();
        customToast.error(response?.data?.message);
      }
    }
  };
  // institutionLoading || degreesLoading || classOfDegreeLoading
  const debouncedSearch = useDebounce((input) => {
    getInstitutionsMutation.mutate(input);
    setInstitutionSearch(input);
  }, 2000);

  const courseDebouncedSearch = useDebounce((input) => {
    getCoursesMutation.mutate(input);
    setInstitutionSearch(input);
  }, 2000);

  const handleSearch = React.useCallback(
    (value) => {
      debouncedSearch(value);
    },
    [debouncedSearch]
  );

  const handleCourseSearch = React.useCallback(
    (value) => {
      courseDebouncedSearch(value);
    },
    [debouncedSearch]
  );

  useEffect(() => {
    if (getInstitutionsMutation.isLoading) {
       setSearchInstitutionLoading(true)
    } else {
      setSearchInstitutionLoading(false)
    }
  }, [getInstitutionsMutation.isLoading]);

  useEffect(() => {
    if (getCoursesMutation.isLoading) {
       setSearchCourseLoading(true)
    } else {
      setSearchCourseLoading(false)
    }
  }, [getCoursesMutation.isLoading]);

  return (
    <div className="fixed z-[10] top-0 left-0 w-[100vw] h-[100vh] bg-black bg-opacity-50">
      <div className="flex items-center justify-center h-full w-full">
        <div className="w-[96%] overflow-y-scroll z-[100000] h-[500px] md:h-[500px] md:w-[600px] bg-white rounded-[15px] p-8 md:p-4">
          <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
            Edit Education
          </h3>
          <div className="flex justify-between mb-4">
            <div className="text-darkBlue text-xs font-medium"></div>
            <div
              className="text-[#F35B24] text-xs font-medium cursor-pointer"
              onClick={deleteEducation}
            >
              Remove option
            </div>
          </div>
          {isLoading ||
          institutionLoading ||
          degreesLoading ||
          classOfDegreeLoading ? (
            <div className="flex justify-center">
              <ScaleLoader color="#87AA4B" className="" />
            </div>
          ) : (
            <form onSubmit={updateEducation}>
              <div className="mb-4 relative">
                {  searchInstitutionLoading &&                 
                    <div className="absolute text-xs z-[100000000000] bg-white w-full py-3 text-center top-[80px]">
                       <ScaleLoader color="#87AA4B" className="" height={10} width={10}/>
                    </div>
                    }
                <label
                  htmlFor="gender"
                  className="text-gray-500 mb-4 text-sm font-medium"
                >
                  Name of Institution
                  <span className="text-red-500 required-dot">*</span>
                </label>
                <CustomDropdown
                  value={
                    selectInstitution
                      ? { value: selectInstitution, label: selectInstitution } // Provide both value and label
                      : null
                  }
                  width={"w-full"}
                  options={
                    institutionLoading || getInstitutionsMutation.isLoading
                      ? [{ label: "Searching...", value: "" }]
                      : institutions
                  }
                  onSearch={(input) => {
                    setSearchInstitutionLoading(true)
                    handleSearch(input);
                  }}
                  onChange={(selectedOption) => {
                    // setSelectedInstitutionId(selectedOption?.value || "");
                    setSelectedInstitution(selectedOption?.label || "");
                    setSelectedInstitutionId(selectedOption?.value);
                    setInstitutionValid(!!selectedOption);
                  }}
                  noOptionsMessage={() =>
                    institutionLoading ? "Searching..." : "No results found"
                  }
                />
                {!institutionValid && (
                  <p className="text-red-500 text-xs mt-1 text-right">
                    Please select an institution
                  </p>
                )}
              </div>

              <div className="mb-4 md:flex gap-x-4 relative">
                  {  searchCourseLoading &&                 
                    <div className="absolute text-xs z-[100000000000] bg-white w-full py-3 md:w-[360px] text-center top-[80px]">
                       <ScaleLoader color="#87AA4B" className="" height={10} width={10}/>
                    </div>
                    }
                <div className="mb-4 md:mb-0 w-full md:w-2/3">
                  <label
                    htmlFor="gender"
                    className="text-gray-500 mb-4 text-sm font-medium"
                  >
                    Courses
                    <span className="text-red-500 required-dot">*</span>
                  </label>
                  <CustomDropdown
                    options={courses}
                    value={
                      courseId
                        ? { value: courseId, label: selectCourse } // Provide both value and label
                        : null
                    }
                    width={"w-[100%]"}
                    onChange={(selectedOption) => {
                      setCourseId(selectedOption?.value || "");
                      setSelectedCourse(selectedOption?.label || "");
                      setCourseValid(!!selectedOption);
                    }}
                    onSearch={(input) => {
                      setSearchCourseLoading(true)
                      handleCourseSearch(input);
                    }}
                  />
                  {!courseValid && (
                    <p className="text-red-500 text-xs mt-1 text-right">
                      Please select a course
                    </p>
                  )}
                </div>
                <div className="mb-4 md:mb-0 w-full md:w-1/3">
                  <label
                    htmlFor="degree"
                    className="text-gray-500 mb-4 text-sm font-medium"
                  >
                    Degree
                    <span className="text-red-500 required-dot">*</span>
                  </label>
                  <CustomDropdown
                    options={
                      degrees?.data?.length
                        ? degrees?.data?.map((degree) => {
                            return { label: degree?.name, value: degree?.id };
                          })
                        : []
                    }
                    value={
                      degreeId
                        ? { value: degreeId, label: selectDegree } // Provide both value and label
                        : null
                    }
                    width={"w-full"}
                    onChange={(selectedOption) => {
                      setDegreeId(selectedOption?.value || "");
                      setSelectedDegree(selectedOption?.label || "");
                      setDegreeValid(!!selectedOption);
                    }}
                  />
                  {!degreeValid && (
                    <p className="text-red-500 text-xs mt-1 text-right">
                      Please select a degree
                    </p>
                  )}
                </div>
              </div>

              <div className="mb-4 md:flex  gap-x-4">
                <div className="w-full  mb-4 md:mb-0">
                  <label
                    htmlFor="class_of_degree"
                    className="text-gray-500 mb-4 text-sm font-medium"
                  >
                    Class Of Degree
                    <span className="text-red-500 required-dot">*</span>
                  </label>
                  <CustomDropdown
                    options={
                      classOfDegrees?.data?.length
                        ? classOfDegrees?.data?.map((classOfDegree) => {
                            return {
                              label: classOfDegree?.name,
                              value: classOfDegree?.id,
                            };
                          })
                        : []
                    }
                    value={
                      classOfDegreeId
                        ? { value: classOfDegreeId, label: selectClassOfDegree } // Provide both value and label
                        : null
                    }
                    width={"w-full"}
                    onChange={(selectedOption) => {
                      setClassOfDegreeId(selectedOption?.value || "");
                      setSelectedClassOfDegree(selectedOption?.label || "");
                      setClassOfDegreeValid(!!selectedOption);
                    }}
                  />
                  {!classofDegreeValid && (
                    <p className="text-red-500 text-xs mt-1 text-right">
                      Please select class of degree
                    </p>
                  )}
                </div>
              </div>

              <div className="mb-8 md:flex gap-x-4">
                <div className="w-full md:w-1/2 mb-4 md:mb-0">
                  <label
                    htmlFor="start_date"
                    className="text-gray-500 mb-1 block text-sm font-medium"
                  >
                    Start Date
                    <span className="text-red-500 required-dot">*</span>
                  </label>
                  <DatePicker
                     selected={startDate}
                     placeholderText="start date (dd/mm/yyyy)"
                     dateFormat="dd/MM/yyyy"
                     className="border border-slate-300 block focus:border-primary active:border-primary py-3 px-3 rounded-lg text-xs text-slate-700 w-full"
                     maxDate={endDate}
                     onChange={(value) => {
                      setStartDate(value);
                      setStartDateValid(true);
                    }}
                  />
                  {!startDateValid && (
                    <p className="text-red-500 text-xs mt-1 text-right">
                      Please select start date
                    </p>
                  )}
                </div>
                <div className="w-full md:w-1/2 mb-4 md:mb-0">
                  <label
                    htmlFor="end_date"
                    className="text-gray-500 mb-1 block text-sm font-medium"
                  >
                    End Date
                    <span className="text-red-500 required-dot">*</span>
                  </label>
                  <DatePicker
                     selected={endDate}
                     placeholderText="end date (dd/mm/yyyy)"
                     dateFormat="dd/MM/yyyy"
                     className="border border-slate-300 block focus:border-primary active:border-primary py-3 px-3 rounded-lg text-xs text-slate-700 w-full"
                     minDate={startDate}
                     onChange={(e) => {
                      setEndDate(e);
                      setEndDateValid(true);
                    }}
                  />
                  {!endDateValid && (
                    <p className="text-red-500 text-xs mt-1 text-right">
                      Please select a degree
                    </p>
                  )}
                </div>
              </div>
              <div className="flex gap-x-10 justify-center">
                <button
                  type="button"
                  className="inline-flex justify-center rounded-md border border-primary  shadow-sm px-8 py-2 md:px-16 md:py-2 bg-white text-sm font-medium text-darkBlue  sm:ml-3 sm:w-auto sm:text-sm mb-4 sm:mb-0"
                  onClick={() => closeModal(false)}
                >
                  Cancel
                </button>

                <button
                  type="submit" disabled={editEducationLoading}
                  className="inline-flex justify-center rounded-md border border-none  shadow-sm px-8 py-2 md:px-16 md:py-2 bg-primary text-sm font-medium text-white sm:ml-3 sm:w-auto sm:text-sm mb-4 sm:mb-0"
                >
                  Update
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}

export default EditEducationModal;
