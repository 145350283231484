import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useMutation, useQuery } from "react-query";
import {
  Link,
  Navigate,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import CustomDropDown from "../../components/CustomDrpdown";
import Jumbotron from "../../components/Jumbotron";
import { useCustomToast } from "../../components/customtoast/CustomToastProvider";
import Footer from "../../layout/Footer";
import UpdatedNavbar from "../../layout/UpdateNavbar";
import { api } from "./../../api/index";
import Search from "./components/Search";
import ReactGA from "react-ga";
import { listings as listingsApi } from "../../api/listings";
import Loader from "../../components/Loader";

ReactGA.pageview(window.location.pathname + window.location.search);

const JobApply = ({ listings, setListings }) => {

  const [searchParams] = useSearchParams();
  const campaign_id = searchParams.get("campaign-id");
  const affiliate_id = searchParams.get("affiliate-id");
  const action = searchParams.get("action");
  const [roles, setRoles] = useState([]);
  const [multipleRoles, setMultipleRoles] = useState([]);
  const { id } = useParams();
  const [newUser, setNewUser] = useState(false);
  const [modal, setModal] = useState(false);
  const isAuth = parseInt(sessionStorage.getItem("is-authenticated"));
  const customToast = useCustomToast();
  const [isValidLink, setIsValidLink] = useState(true);

  const { data: campaign, isLoading } = useQuery(
    ["get single job listing"],
    () => {
      return listingsApi.getSingleJobListingv2({ id, link: window.location.href });
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        const isMultiRoles = data?.data?.role_selection !== 0
        const pipelineRoles = isMultiRoles ? [
          ...data?.data?.pipelines.map((pipeline) => {
            return { ...pipeline, status: false };
          }),
        ] : []

        setRoles(pipelineRoles);
        setMultipleRoles(
          pipelineRoles
            ?.map((pipeline) => {
              return { label: pipeline.role.name, value: pipeline.role.id };
            })
            .slice(0, 1)
        );
      },
      onError: (error) => {
        if (error?.response?.status) setIsValidLink(false);
      },
    }
  );

  const submitAffiliateMutation = useMutation(api.submitAffiliate, {
    onSuccess: (data) => {
      sessionStorage.setItem("affilate_tag", affiliate_id);
    },
  });

  //const setVisitorFingerPrintMutation = useMutation(api.setVisiorFingerprint);

  useEffect(() => {
    if(affiliate_id !== null && campaign_id !== null) {
      submitAffiliateMutation.mutate({
        campaignId: campaign_id,
        affiliateSlug: affiliate_id,
      });
    }
  }, []);

  useEffect(() => {
    if (action === "login" && !isAuth) {
      setModal(!modal);
    }
    //if(affiliate_id && affiliate_id !== 'null'){
    /*const setFp = async () => {
      const fp = await FingerprintJS.load();
      const { visitorId } = await fp.get();
      sessionStorage.setItem("visitor-fingerprint", visitorId);
      setVisitorFingerPrintMutation.mutate({
        tag: visitorId,
        campaign_id: campaign_id,
        affiliate_slug: affiliate_id,
      });
      //  };
    }; 
    setFp(); */
  }, []);

  var options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    localeMatcher: "best fit",
    timeZone: "Africa/Lagos", // Set the time zone to Nigeria
  };

  const timeAgo = (date) => {
    const currentDate = new Date();
    const timestamp = date.getTime();
    const currentTimestamp = currentDate.getTime();
    const timeDifference = currentTimestamp - timestamp;

    // Define time units in milliseconds
    const minute = 60 * 1000;
    const hour = 60 * minute;
    const day = 24 * hour;
    const week = 7 * day;
    const month = 30 * day;
    const year = 365 * day;

    if (timeDifference < minute) {
      return "Just now";
    } else if (timeDifference < hour) {
      return Math.floor(timeDifference / minute) + "m ago";
    } else if (timeDifference < day) {
      return Math.floor(timeDifference / hour) + "h ago";
    } else if (timeDifference < week) {
      return Math.floor(timeDifference / day) + "d ago";
    } else if (timeDifference < month) {
      return Math.floor(timeDifference / week) + "w ago";
    } else if (timeDifference < year) {
      return Math.floor(timeDifference / month) + "mo ago";
    } else {
      return Math.floor(timeDifference / year) + "y ago";
    }
  };

  const navigate = useNavigate();

  useEffect( () => {
    const close_date = new Date(campaign?.data?.end_date).getTime();
    sessionStorage.setItem('application-status', !(new Date().getTime() < close_date) ? 'close' : 'open' )
  }, [campaign_id, campaign])

  const links_ = [
    {
      path: "/",
      title: "Home",
      slug: "home",
    },
    {
      path: "/job-listings",
      title: "Job Listings",
      slug: "job-listings",
    },
    {
      path: `/job-listings/apply/${id}?application=${campaign?.data?.title}`,
      title: "Apply",
      slug: "job-details",
    },
  ];

  const encodeUrl = (url) => encodeURIComponent(url);

  const saveJobInABookmarkMutaion = useMutation(api.saveJobInABookmark, {
    onSuccess: (data) => {
      toast.remove();
      toast.success("Bookmark saved.");
    },
    onError: (error) => {
      toast.remove();
      toast.error(error.response.data.error);
    },
    onMutate: () => {
      toast.loading("loading...");
    },
  });

  const handleLogout = async () => {
    try {
      // toast.loading("Logging you out...");
      await api.logout();
      // toast.remove();
      // toast.success(data?.message);
      sessionStorage.setItem("is-authenticated", "0");
    } catch ({ response }) {
      if (response.status === 401) {
        sessionStorage.setItem("is-authenticated", "0");
      } else {
        toast.error(response?.data?.message);
      }
      // toast.remove();
    }
  };

  const handleRegister = () => {
    if(isAuth) toast.error('Hello, You are already logged in.')
    else navigate(`/register?campaign-id=${id}&redirect=job-application&application=${campaign?.data?.title}`)
  };

  const applyForARole = () => {
    if (!isAuth) {
      navigate(`/login?campaign-id=${id}&redirect=job-application&application=${campaign?.data?.title}`)
      return sessionStorage.setItem(
        "application_data",
        JSON.stringify({
          id: campaign_id,
          affiliateSlug: affiliate_id === null ? "" : affiliate_id,
          roles_selected: multipleRoles?.map((role) => role?.value),
        })
      );
    } else {
      const close_date = new Date(campaign?.data?.end_date).getTime();
      if (!(new Date().getTime() < close_date)) {
        toast.error(`
                    Sorry, application closed on ${new Date(
                      campaign?.data?.end_date
                    ).toLocaleDateString()}
                `);
      } else if (
        campaign?.data?.role_selection === 1 &&
        !multipleRoles?.length
      ) {
        toast.error("Please select at least one role");
      } else {
        navigate(`/job-listings/submit-application/${id}?application=${campaign?.data?.title}`)
        //save application info to session storage and use same after profile updateoae
        return sessionStorage.setItem(
          "application_data",
          JSON.stringify({
            id: campaign_id,
            affiliateSlug: affiliate_id === null ? "" : affiliate_id,
            roles_selected: multipleRoles?.map((role) => role?.value),
          })
        );
      }
    }
  };

  const savedJobInABookmark = () => {
    if (!parseInt(sessionStorage.getItem("is-authenticated"))) {
      navigate("/login");
    } else {
      saveJobInABookmarkMutaion.mutate(id);
    }
  };

  if (id === "null" || id === null) {
    return <Navigate to={"/job-listings"} />;
  }  
  else {
    return (
      <div className="force-white-mode">
        {isValidLink ? (
          <div className="px-1">
            <UpdatedNavbar />
            <Jumbotron links={links_} active={"job-details"} />
            {
              isLoading ? <Loader /> :
              <>
                <div className="md:flex justify-between mt-4 px-md-4">
              <h5 className="mb-[12px] md:mb-0 md:mt-5 text-gray-900">Job Details</h5>
              <Search  setListings={setListings} />
            </div>
            <section className="mt-0  mx-2 mb-5 p-[8px] md:py-8 border md:flex justify-between border-gray-200 rounded-lg">
              <div className="w-full md:w-[65%] md:pl-5">
                <h5 className="text-2xl font-semibold mt-2 text-gray-900">
                  {campaign?.data?.title}
                </h5>
                <p className="text-md mt-2 font-semibold">
                  {campaign?.data?.client?.name &&
                   <span className="font-normal bg-blue-100 mr-5 text-blue-500 py-2 px-4 text-xs">
                    {campaign?.data?.client?.name}
                  </span>}
                  <button className="py-1 text-gray-900 font-normal text-xs bi bi-alarm bg-gray-100 px-3">
                    &nbsp;
                    {timeAgo(
                      new Date(
                        campaign?.data?.created_at !== null &&
                          campaign?.data?.created_at
                      )
                    )}
                  </button>
                </p>
                <p className="text-sm mt-2  text-gray-900 font-semibold">
                  Application Status: &nbsp;
                  {new Date().getTime() <
                  new Date(campaign?.data?.end_date).getTime() ? (
                    <span className="text-sm font-normal text-armyGreen">
                      Open
                    </span>
                  ) : (
                    <span className="text-sm font-normal text-red-500">
                      Closed
                    </span>
                  )}
                </p>
                <p className="text-sm mt-2  text-gray-900 font-semibold">
                  Closing Date: &nbsp;
                  <span className="text-sm font-normal">
                    {`${new Date(campaign?.data?.end_date).toLocaleDateString(
                      "en-NG",
                      options
                    )}`}
                  </span>
                </p>
                <p className="text-sm text-gray-900 mt-2">
                  <div
                    className="inline-block text-gray-900 job-description-container"
                    dangerouslySetInnerHTML={{
                      __html: campaign?.data?.description,
                    }}
                  />
                </p>
              </div>
              <div className="w-full mt-[10px] md:mt-0 rounded-lg  md:mt-0 md:w-[35%]">
                <div className=" bg-gray-100 h-auto py-5 px-4">
                  {campaign?.data?.role_selection === 1 ? (
                    <section>
                      <h5 className="capitalize text-gray-900 text-sm border-b border-gray-300 mb-3 pb-1">
                        Select Roles you are interested in.
                      </h5>
                      <CustomDropDown
                        options={
                          roles?.length
                            ? roles?.map((pipeline) => {
                                return {
                                  label: pipeline.role.name,
                                  value: pipeline.role.id,
                                };
                              })
                            : []
                        }
                        value={multipleRoles}
                        width={"w-full"}
                        multiSelect={true}
                        onChange={(values) => setMultipleRoles(values)}
                      />
                    </section>
                  ) : null}
                  <p className="text-sm text-gray-900 mb-4 pl-3 pt-2">
                    {" "}
                    Apply or save in a bookmark to apply later.
                  </p>
                  <div className="md:flex">
                    <button
                      onClick={applyForARole}
                      className="button-md text-xs mr-3"
                    >
                      {isAuth ? "Apply" : "Login to apply"}
                    </button>
                    <button
                      onClick={savedJobInABookmark}
                      className="button-md text-xs"
                      style={{ background: "#008" }}
                    >
                      {isAuth
                        ? "Save in bookmark "
                        : "Login to save bookmark "}
                    </button>
                  </div>
                  <div className="mt-3">
                    <span className="text-sm">New User?</span>
                    {/* <Link to={'/register'} className='text-sm text-blue-500 ml-3 py-2  underline rounded-full'>
                        Register
                    </Link> */}
                    <button
                      onClick={handleRegister}
                      className="text-sm text-blue-500 ml-1 py-2 underline rounded-full"
                    >
                      Register
                    </button>{" "}
                    and update your profile to apply
                  </div>
                  <div className="social-media text-md mt-4 md:ml-5">
                    <span className="text-sm">SHARE ON</span> : &nbsp; &nbsp;
                    <a
                      href={`https://www.facebook.com/sharer/sharer.php?u=${encodeUrl(
                        document.URL
                      )}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="bi text-2xl bi-facebook me-3 text-blue-500"
                    ></a>
                    <a
                      href={`https://api.whatsapp.com/send?text=${encodeUrl(
                        campaign?.data?.name
                      )}%20${encodeUrl(document.URL)}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="bi text-2xl bi-whatsapp me-3"
                    ></a>
                    <a
                      href={`https://twitter.com/intent/tweet?url=${encodeUrl(
                        document.URL
                      )}&text=${encodeUrl(campaign?.data?.name)}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="bi text-2xl bi-twitter me-3"
                    ></a>
                    <a
                      href={`https://www.linkedin.com/shareArticle?url=${encodeUrl(
                        document.URL
                      )}&title=${encodeUrl(campaign?.data?.name)}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="bi text-2xl bi-linkedin me-3"
                    ></a>
                  </div>
                </div>
              </div>
            </section>
              </>
            } 
            <Footer />
          </div>
        ) : (
          <div className="h-[100vh] w-full flex justify-center items-center">
            <section className="mt-20 text-center">
              <h5 className="bi bi-exclamation-triangle text-center text-7xl"></h5>
              <p className="text-center text-lg mb-3 mt-2">
                <strong className="text-lg text-red-500">Error!</strong>
                &nbsp; Invalid Application Link
              </p>
              <Link
                to={"/job-listings"}
                className="text-sm bg-armyGreen text-white py-3 px-12 rounded-full"
              >
                View Job Listings
              </Link>
            </section>
          </div>
        )}
      </div>
    );
  }
};

export default JobApply;
