import { AxiosInstance } from "axios";
import { createApiClient } from "./apiClient";
import { 
    getCoursesType, createEducationType,
    getInstitutionsType,
    getUserBiodataType, updateUserBiodataType, userId,
    updateEducationType,
    deleteEducationType,
    createUserExperinceType,
    updateUserExperinceType,
    deleteUserExperinceType,
    addNyscDataType,
  } 
  from "./types/profile.types";

const client: AxiosInstance = createApiClient()

export const profile = {

 getUserBiodatav2: ({ userID } : getUserBiodataType ) =>
  client.get(`candidates/${userID}/profile/bio-data`).then(({ data }) => data),

 getStatesv2: () => client.get(`/states?per_page=40`).then(({ data }) => data),
 
 getDegreesv2: () => client.get(`/degrees`).then(({ data }) => data),

  getClassOfDegreesv2: () =>
    client.get(`/class-of-degrees`).then(({ data }) => data),

  updateUserBiodatav2: ({payload, userId} : {payload: updateUserBiodataType, userId: userId} ) =>
    client
      .patch(`candidates/${userId}/profile/bio-data`, { ...payload })
      .then(({ data }) => data),

 getProfileStatusv2: ( userId: userId) =>
   client.get(`/candidates/${userId}/status`).then(({ data }) => data),

 getUserEducationListv2: (userId: userId) =>
    client.get(`/candidates/${userId}/profile/education`)
    .then(({ data }) => data),

 getInstitutionsv2: (search: getInstitutionsType) => client
    .get(`/institutions?per_page=10${(typeof search === 'string' ) ?  `&search=${search}` : '' }`)
   .then(({ data }) => data),

 getCoursesv2: (search: getCoursesType) => client
   .get(`/courses?per_page=10${(typeof search === 'string' ) ?  `&search=${search}` : '' }`)
   .then(({ data }) => data),

   createUserEducationv2: ({ payload, userId } : createEducationType) =>
    client
      .post(`/candidates/${userId}/profile/education`, { ...payload })
      .then(({ data }) => data),
  
  updateUserEducationv2: ({id, payload, userId}: updateEducationType) =>
    client
      .patch(`/candidates/${userId}/profile/education/${id}`, { id, ...payload })
      .then(({ data }) => data),

  deleteUserEducationv2: ({id, userId}: deleteEducationType) =>
    client
      .delete(`/candidates/${userId}/profile/education/${id}`)
      .then(({ data }) => data),

  createUserExperiencev2: ({payload, userId}: createUserExperinceType) =>
    client
      .post(`/candidates/${userId}/profile/experiences`, { ...payload })
      .then(({ data }) => data),

  updateUserExperiencev2: ({id, payload, userId}: updateUserExperinceType) =>
    client
      .patch(`/candidates/${userId}/profile/experiences/${id}`, { id, ...payload })
      .then(({ data }) => data),

  getUserExperienceListv2: ({userID}: userId) =>
    client.get(`/candidates/${userID}/profile/experiences`).then(({ data }) => data),

  deleteUserExperience: ( { id, userId } : deleteUserExperinceType ) =>
    client
      .delete(`/candidates/${userId}/profile/experiences/${id}`)
      .then(({ data }) => data),

  getNyscv2: ({userID}: userId) =>
    client.get(`/candidates/${userID}/profile/nysc-data`).then(({ data }) => data),

  AddNyscData: ({ payload, userId } : addNyscDataType) =>
    client
      .post(`/candidates/${userId}/profile/nysc-data`, { ...payload })
      .then(({ data }) => data),
}