import React, { useState } from "react";
import AsyncSelect from "react-select/async";
import moment from "moment";
import { useQuery, useMutation } from "react-query";
import toast from "react-hot-toast";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useCustomToast } from "../../../components/customtoast/CustomToastProvider";
import { ScaleLoader } from "react-spinners";
import ReactGA from "react-ga";
import { profile } from "../../../api/profile";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { formatDate, reverseDate } from '../../../utils/dateFormatter';

function EditBiodataModal({ closeModal, biodata, refetch, refetchStatus }) {
  
  const customToast = useCustomToast();
  const { isLoading, data: states } = useQuery("get-states", profile.getStatesv2);
  const [selectedDate, setSelectedDate] = useState(
    biodata?.bioData?.date_of_birth ? reverseDate(biodata?.bioData?.date_of_birth) : null
  );
  const [phoneNumber, setPhoneNumber] = useState(biodata?.phone_number);
  const [name, setName] = useState(biodata?.name);
  const [email, setEmail] = useState(biodata?.email);
  const [gender, setGender] = useState(biodata?.bioData?.gender);
  const [selectedCountry, setSelectedCountry] = useState("NG");
  const [ageMessage, setAgeMessage] = useState("");
  const user = JSON.parse(sessionStorage.getItem('user'))
  const {isLoading: updateBioDataLoading, mutateAsync} = useMutation(profile.updateUserBiodatav2)
  const [nameValid, setNameValid] = useState(true);
  const [genderValid, setGenderValid] = useState(true);
  const [selectedDateValid, setSelectedDateValid] = useState(true);
  const [selectStateValid, setSelectStateValid] = useState(true);
  const [selectResidenceValid, setSelectResidenceValid] = useState(true);
  const [phoneNumberValid, setPhoneNumberValid] = useState(true);

  const [selectState, setSelectedState] = useState(
    biodata?.bioData?.stateOfOrigin?.name || ""
  );
  const [selectStateId, setSelectedStateId] = useState(
    biodata?.bioData?.stateOfOrigin?.id || ""
  );
  const [selectResidence, setSelectedResidence] = useState(
    biodata?.bioData?.stateOfResidence?.name || ""
  );
  const [selectResidenceId, setSelectedResidenceId] = useState(
     biodata?.bioData?.stateOfResidence?.id || ""
  );

  const filteredStates = (inputValue) => {
    return states?.data
      .filter((state) =>
        state.name.toLowerCase().includes(inputValue.toLowerCase())
      )
      .map((s) => ({ value: s.id, label: s.name }));
  };

  const loadOptions = (inputValue, callback) => {
    callback(filteredStates(inputValue));
  };

  const validatePhoneNumber = (number) => {
    // Regex pattern for Nigeria and foreign phone numbers
    const nigeriaPattern = /^(\+?234|0)[789]\d{9}$/;
    if (selectedCountry === "NG") {
      return nigeriaPattern.test(number) && number.length <= 14; // Enforce 14 character limit
    } else {
      return nigeriaPattern.test(number) && number.length <= 14; // Enforce 14 character limit
    }
  };

  const handlePhoneNumberChange = (value) => {
    setPhoneNumber(value);
    setPhoneNumberValid(validatePhoneNumber(value)); // Validate when the value changes
  };

  const handleDateChange = (selectedDate_) => {

    const currentYear = new Date().getFullYear();
    const selectedYear = new Date(selectedDate_).getFullYear()
    const age = currentYear - selectedYear
    
    if (age > 18 ) {
      setSelectedDate(selectedDate_);
      setAgeMessage("");
      setSelectedDateValid(true)
    } else {
      setAgeMessage("Must be 18 years and above.");
      setSelectedDate(null)
      setSelectedDateValid(false)
    }
  };

  const updateBiodata = async (e) => {
    e.preventDefault();

    setNameValid(name.trim() !== "");
    setGenderValid(gender !== "");
    setSelectedDateValid(selectedDate !== null);
    setSelectStateValid(selectState !== "");
    setSelectResidenceValid(selectResidence !== "");

    if (
      !nameValid ||
      !genderValid ||
      !selectedDateValid ||
      !selectStateValid ||
      !selectResidenceValid ||
      !phoneNumberValid
    ) {
      toast.error("Please fill out all required fields.");
      return;
    }
    if (ageMessage) {
      toast.error("Must be 18 years and above.");
      return;
    }

    let payload = {
      name: name,
      gender: gender,
      date_of_birth: moment(selectedDate).format("YYYY-MM-DD"),
      state_of_origin_id: selectStateId,
      state_of_residence_id: selectResidenceId,
      email: email,
      phone_number: phoneNumber,
    };

    try {
      toast.loading("loading...");
      const res = await  mutateAsync({payload, userId: user?.user?.user_id } )
      toast.remove();
      closeModal(false);
      customToast.success(res?.message);
      ReactGA.event({
        category: "User",
        action: "Update Bio-data",
        label: "User Updated Bio-Data",
      });
      await refetch();
      await refetchStatus();
    } catch ({ response }) {
      // console.log(error);
      toast.remove();
      customToast.error(response?.data?.message);
    }
  };

  return (
    <div className="fixed z-[10] top-0 left-0 w-[100vw] h-[100vh] bg-black bg-opacity-50">
      <div className="flex items-center justify-center h-full w-full">
        <div className="w-[96%] overflow-y-scroll z-[100000] h-[500px] md:h-[500px] md:w-[600px] bg-white rounded-[15px] p-8 md:p-4">
          <h3 className="text-md leading-6 font-medium text-gray-900 mb-3">
            Edit Biodata
          </h3>
          {isLoading ? (
            <div className="flex justify-center">
              <ScaleLoader color="#87AA4B" className="" />
            </div>
          ) : (
            <form onSubmit={updateBiodata}>
              <div className="mb-4 ">
                <div className="">
                  <label
                    htmlFor="name"
                    className="text-gray-500 mb-6 text-sm font-medium"
                  >
                    Name
                    <span className="text-red-500 required-dot">*</span>
                  </label>
                </div>
                <input
                  type="text"
                  id="name"
                  className={` rounded-lg  flex-1 appearance-none border ${
                    !nameValid ? "border-red-500" : "border-lightGrey"
                  }   w-full py-4 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-sm focus:outline-none focus:ring-2 focus:ring-lightGrey focus:border-transparent`}
                  name="name"
                  placeholder="Your name"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                    setNameValid(e.target.value.trim() !== "");
                  }}
                />
                {!nameValid && (
                  <p className="text-red-500 text-xs mt-1">Name is required</p>
                )}
              </div>
              <div className="mb-4 ">
                <label
                  htmlFor="gender"
                  className="text-gray-500 mb-6 text-sm font-medium"
                >
                  Gender
                  <span className="text-red-500 required-dot">*</span>
                </label>
                <div className="flex gap-x-20 items-center">
                  <div className="flex items-center gap-x-2">
                    <input
                      name="gender"
                      type="radio"
                      value="Male"
                      checked={gender === "Male"}
                      onChange={(e) => {
                        setGender(e.target.value);
                        setGenderValid(true); // Mark gender as valid
                      }}
                      className={`mr-1 dark:bg-white dark:text-white dark:appearance-none mt-0.5 h-5 w-5 rounded-full border-2 border-solid ${
                        !genderValid ? "border-red-500" : "border-neutral-300"
                      } `}
                    />
                    Male
                  </div>
                  <div className="flex items-center gap-x-2">
                    <input
                      name="gender"
                      type="radio"
                      value="Female"
                      className={`mr-1  dark:bg-white dark:text-white dark:appearance-none mt-0.5 h-5 w-5 rounded-full border-2 border-solid ${
                        !genderValid ? "border-red-500" : "border-neutral-300"
                      } `}
                      checked={gender === "Female"}
                      onChange={(e) => {
                        setGender(e.target.value);
                        setGenderValid(true); // Mark gender as valid
                      }}
                    />{" "}
                    Female
                  </div>
                </div>
                {!genderValid && (
                  <p className="text-red-500 text-xs mt-1">
                    Please select a gender
                  </p>
                )}
              </div>

              <div className=" flex flex-col mb-6">
                <label
                  htmlFor="date_of_birth"
                  className="text-gray-500 mb-2 text-sm font-medium"
                >
                  Date of Birth
                  <span className="text-red-500 required-dot">*</span>
                </label>
                <DatePicker
                     selected={selectedDate}
                     placeholderText="(dd/mm/yyyy)"
                     dateFormat="dd/MM/yyyy"
                     maxDate={new Date()}
                     className="border pr-16 border-slate-300 block w-full focus:border-primary active:border-primary py-4 px-3 rounded-lg text-xs text-slate-700 "
                     onChange={(value) => {
                      handleDateChange(value)
                    }}
                  />
                {ageMessage && (
                  <p className="text-red-500 text-xs mt-1">{ageMessage}</p>
                )}
              </div>
              <div className="md:flex gap-x-3">
                <div className="w-full md:w-1/2 mb-4 md:mb-0 ">
                  <label
                    htmlFor="state"
                    className="text-gray-500 mb-6 text-sm font-medium"
                  >
                    State of Residence
                    <span className="text-red-500 required-dot">*</span>
                  </label>
                  <AsyncSelect
                    cacheOptions
                    loadOptions={loadOptions}
                    defaultOptions
                    placeholder="Search state"
                    noOptionsMessage={() => "Search for state"}
                    value={
                      selectResidenceId
                        ? { value: selectResidenceId, label: selectResidence } // Provide both value and label
                        : null // No value selected initially
                    }
                    onChange={(selectedOption) => {
                      setSelectedResidenceId(selectedOption?.value || "");
                      setSelectedResidence(selectedOption?.label || "");
                      setSelectResidenceValid(!!selectedOption);
                    }}
                    className={`border rounded-lg focus:outline-none focus:ring-2 focus:ring-lightGrey focus:border-transparent ${
                      !selectResidenceValid
                        ? "border-red-500 focus:ring-red-500"
                        : "border-lightGrey"
                    }`}
                  />
                  {!selectResidenceValid && (
                    <p className="text-red-500 text-xs mt-1">
                      Please select a state
                    </p>
                  )}
                </div>
                <div className="w-full md:w-1/2 mb-4 md:mb-0 ">
                  <label
                    htmlFor="state"
                    className="text-gray-500 mb-6 text-sm font-medium"
                  >
                    State of Origin
                    <span className="text-red-500 required-dot">*</span>
                  </label>
                  <AsyncSelect
                    cacheOptions
                    loadOptions={loadOptions}
                    defaultOptions
                    placeholder="Search state"
                    noOptionsMessage={() => "Search for state"}
                    value={
                      selectStateId
                        ? { value: selectStateId, label: selectState } // Provide both value and label
                        : null // No value selected initially
                    }
                    onChange={(selectedOption) => {
                      setSelectedStateId(selectedOption?.value || "");
                      setSelectedState(selectedOption?.label || "");
                      setSelectStateValid(!!selectedOption);
                    }}
                    className={`border rounded-lg focus:outline-none focus:ring-2 focus:ring-lightGrey focus:border-transparent ${
                      !selectStateValid
                        ? "border-red-500 focus:ring-red-500"
                        : "border-lightGrey"
                    }`}
                  />
                  {!selectStateValid && (
                    <p className="text-red-500 text-xs">
                      Please select a state
                    </p>
                  )}
                </div>
              </div>

              <div className="md:flex gap-x-3 mt-4">
                <div className="w-full md:w-1/2 mb-4 md:mb-0">
                  <label
                    htmlFor="email"
                    className="text-gray-500 mb-6 text-sm font-medium"
                  >
                    Email
                  </label>

                  <input
                    type="text"
                    id="email"
                    className=" rounded-lg  flex-1 appearance-none border border-lightGrey w-full py-4 px-4 bg-customGrey text-gray-700 placeholder-gray-400 shadow-sm text-sm focus:outline-none focus:ring-2 focus:ring-lightGrey focus:border-transparent"
                    name="email"
                    placeholder="Your email"
                    disabled
                    value={email}
                  />
                </div>
                <div className="w-full md:w-1/2 mb-4 md:mb-0">
                  <label
                    htmlFor="phone_number"
                    className="text-gray-500 mb-6 text-sm font-medium"
                  >
                    Phone
                    <span className="text-red-500 required-dot">*</span>
                  </label>

                  <PhoneInput
                    className={`rounded-lg  flex-1 appearance-none border ${
                      !phoneNumberValid ? "border-red-500" : "border-lightGrey"
                    }  w-full py-4 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-sm focus:outline-none focus:ring-2 focus:ring-lightGrey focus:border-transparent`}
                    limitMaxLength={true}
                    international={true}
                    defaultCountry="NG"
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    onCountryChange={(v) => setSelectedCountry(v)}
                    placeholder="Your phone number"
                  />
                  {!phoneNumberValid && (
                    <p className="text-red-500 text-xs">
                      Please enter a valid phone number
                    </p>
                  )}
                </div>
              </div>

              <div className="flex gap-x-10 justify-center mt-10">
                <button
                  type="button"
                  className="inline-flex justify-center rounded-md border border-armyGreen  shadow-sm px-8 py-2 md:px-16 md:py-2 bg-white text-sm font-medium text-armyGreen  sm:ml-3 sm:w-auto sm:text-sm mb-4 sm:mb-0"
                  onClick={() => closeModal(false)}
                >
                  Cancel
                </button>

                <button
                  type="submit" disabled={updateBioDataLoading}
                  className="inline-flex justify-center rounded-md border border-none  shadow-sm px-8 py-2 md:px-16 md:py-2 bg-armyGreen text-sm font-medium text-white sm:ml-3 sm:w-auto sm:text-sm mb-4 sm:mb-0"
                >
                  Update Biodata
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}

export default EditBiodataModal;
